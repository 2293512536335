.relatorio_controle .total{
    text-align: center;
}

.relatorio_controle .common{
    background-color: #fff !important;
    font-weight: bold;
}

.relatorio_controle .hour{
    background-color:  #98FB98 !important;
    font-weight: bold;
}

.relatorio_controle .three{
    background-color:  #F0E68C !important;
    font-weight: bold;
}

.relatorio_controle .six{
    background-color:  #FFA500 !important;
    font-weight: bold;
}

.relatorio_controle .twelve{
    background-color:  #FFA07A !important;
    font-weight: bold;
}

.relatorio_controle .twenty_four{
    background-color:  #FF0000 !important;
    font-weight: bold;
    color: #fff;
}


.relatorio_controle .delay{
    background-color: #FA8072 !important;
    font-weight: bold;
    color: #fff;
}