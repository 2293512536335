/**/
.line_link{
  cursor: pointer;
  vertical-align: middle;
}

.line_link:hover{
font-weight: bold;
}

.table {
  color: #779198;
}

.table td, .table th {
  padding: .15rem .75rem;
  font-size: 14px;
  height: 28px;
}

.table th {
  font-family: "Segoe UI Bold";
  font-weight: normal;
}

/** Grid Info */
.table-grid-info th{
  text-align: center;
  background-color: #779198 !important;
  color: #fff;
  padding: 5px !important;
  font-size: 12px !important;
  border-right: 1px solid #fff;
  margin-right: 5px !important;
}

.table-grid-info td{
  text-align: center;
  font-size: 12px !important;
}

/** Total Alertas */
.table-total-alerts{
  margin: 0 auto;
}

.table-total-alerts th{
  text-align: center;
  padding: 5px !important;
}

.table-total-alerts .sound{
  text-align: right;
}

.link{
  cursor: pointer;
}

.info_window{
  font-size: 11px !important;
  margin: 5px !important;
  border: 1px #779198 solid;
}

.ajs-message{
  color: #fff !important;
  text-align: center !important;
  font-weight: bold;
}

/************************/
.table_grid{
  border: 1px #ccc solid;
  border-radius: 10px;
}

.table_grid th{
  background-color: #E9ECEF;
}

.table_grid td{
  vertical-align: middle;
}

.table_grid .option{
  text-align: center;
}

.table_grid .option button{
  border: 2px red solid;
  background-color: #D70000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
}

.table_grid_filter{
  width: 100%;
  margin-bottom: 10px; 
}

.loader{
  display: none;
}

.inputSelectAll{
  float: right;
  text-align: right;
}

.icon-admin{
  margin-left: 50px !important;
}

.icon-operacao{
  color: #fff !important;
}

/**** Row */

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}