body {
  font-family: "Segoe UI Regular" !important;
  font-size: 13px;
  color: #779198;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius:50%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius:10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCC; 
  border-radius:10px;
  transition:0.1s ease-in
}
.tree{
background-color:transparent !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/*Titles*/
h1 {
  font-family: "Segoe UI Bold";
  font-size: 24px;
  font-weight: normal;
}
h2 {
  font-family: "Segoe UI Bold";
  font-size: 20px;
  font-weight: normal;
}

h3 {
  font-family: "Segoe UI Bold";
  font-size: 16px;
  font-weight: normal;
}

/*Fonts*/
b, strong, label {
  font-family: "Segoe UI Bold";
  font-weight: normal;
}
#menu-informacoes div{
  display: inline-block;
}
label {
  margin-bottom: 0;
  display: block;
}
a {
  color: inherit !important;
  transition: 0.1s ease-in;
}
.text-danger{
  color:#FFF !important;
}

.font-12 {
  font-size: 12px !important
}

.box-login {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3c494c+0,779198+100 */
  background: #3c494c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #3c494c 0%, #779198 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #3c494c 0%, #779198 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #3c494c 0%, #779198 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3c494c", endColorstr="#779198", GradientType=0);
  /* IE6-9 */

  width: 555px;
  margin: auto;
  color: #FFF;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.form-container {
  width: 70%;
  margin: auto;
}

.input-form {
  border-radius: 10px !important;
  width: 80% !important;
  height: 35px !important;
  border: 0;
  padding: 0;
  padding-left: 10px !important;
  font-size: 13px;
  color: #000;
  display: unset;
}

.submit-login {
  width: 81%;
  margin-top: 10px;
  margin-bottom: 7px;
  float: right;
  border-radius: 10px;
  border: 0;
  font-size: 14px;
  height: 35px;
  color: #FFF;
  font-weight: bold;
  background: rgb(215, 0, 0);
  background: linear-gradient(180deg, rgba(215, 0, 0, 1) 0%, rgba(108, 0, 0, 1) 100%);
  cursor: pointer;
  margin-bottom: 20px;
}

.form-container label {
  font-size: 12px;
  width: 18%;
  padding-right: 2% !important;
  text-align: left;
  font-weight: bold;
  float: left;
  padding-top: 6px;
}

.footer-login {
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .box-login {
    width: 90%;
    margin: auto;
    background: none;
    color: #718990 !important;
    padding-top: 0;
    border-top: 1px solid #c1c1c1 !important;
    border-radius: 0;
    margin-top: 20px;
    border-bottom: 1px solid #c1c1c1 !important;
  }
  .bg-gray-mob{
    background-color:#f7f7f7;
    height: 100%;
    width: 80% !important;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-top: 0 !important;
  }

  .form-container {
    width: 100%;
    padding: 20px 0;
    padding-bottom: 0;
  }

  .img-top-mob {
    width: 100%;


  }

  .form-container label {
    width:100%;
    color: #718990 !important;
  }

  .input-form {
    width:100%;
  }
  .submit-login{
    width:100%;
    margin-top: 20px;
  }

  .footer-box-login small{
    width:100%;
    text-align: center;
    padding: 5px;
  }

}
.card {
  background: #F7F7F7;
  border-color: #779198;
  border-radius: 5px;
}
.h-vh10 {
  height: 10vh;
}
.h-vh15 {
  height: 15vh;
}
.h-vh20 {
  height: 20vh;
}
.h-vh25 {
  height: 25vh;
}
.h-vh35 {
  height: 35vh;
}
.h-vh50 {
  height: 50vh;
}
.h-vh60 {
  height: 60vh;
}


/*Menu Vehicle*/
.menu-vehicle {
  display: block;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.menu-vehicle li {
  cursor: pointer;
  width: 13%;
  display: inline-block;
  margin: 0px;
  padding: 3px;
  background: #779198;
  border-radius: 5px;
  color: #FFF;
  text-align: center;
  margin-right:0.2vw;
}

.menu-vehicle li:hover {
  background: rgb(119,145,152);
  background: linear-gradient(190deg, rgba(119,145,152,1) 0%, rgba(65,79,82,1) 100%);
}

.icon-box {
  height: 20px;
  max-width: 30px;
  width: 90%;
  padding-bottom: 3px;
  text-align: center;
  margin: auto;
}
.menu-vehicle img {
  width: 100%;
  height: 100%;
}
.menu-vehicle hr {
  border-color: #FFFFFF ;
  margin: 3px;
}
.form-control {
  border-color: #779198;
}
.btn-light {
  background-color: #FFFFFF;
  border-color: #779198;
}
.card-white {
  background: #FFFFFF;
  border: 1px solid#779198;
  border-radius: 5px;
}

.ml-35 {
  margin-left: 1.1rem !important;
}
.brd-right {
  border-right: 1px solid #dee2e6;
}
.half-card {
  display: inline-block;
  width: 49%;
  height:115px;
  overflow-y:auto;
}


.ml1 {
  margin-left: 1%;
}
@media screen and (max-width: 991px) { 
  .half-card {
    width: 100%;
  }
  .ml1 {
    margin-left: 0;
  }
}

.h100 {
  height: 100%;
}
.form-group {
  margin-bottom: .3rem;
}

.btn-sml {
  text-align: center;
  display: inline-block;
  background: #779198;
  border-radius: 5px;
  padding: 3px 3px 7px 3px;
  cursor: pointer;
  min-width: 30px;
  margin-right:0.2vw;
}
.btn-sml img {
  height: 13px;
}
.btn-sml:hover {
  background: rgb(119,145,152);
  background: linear-gradient(190deg, rgba(119,145,152,1) 0%, rgba(65,79,82,1) 100%);
}
.btn-primary {
  background: #D70000;
  border-color: #D70000;
}
.btn-primary:hover {
  color: #fff;
  background-color: #A70000;
  border-color: #A70000;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #A70000;
  border-color: #A70000;
  box-shadow: 0 0 0 0.2rem rgba(215, 0, 0, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #A70000;
  border-color: #A70000;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #A70000;
  border-color: #A70000;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 0, 0, 0.5);
}

.navbar{
  padding:0 !important;
  display:block;
}
.navbar-nav{
  display:flex;
}
@media screen and (max-width: 1200px) {
  .navbar-nav{
    display: flex;
    clear: both;
  }
}
/*Modal*/
.modal-footer {
  display: block;
  justify-content: normal;
}
/* Menu */
header {
  display: block;
  border-bottom: 5px solid #D70000;
}
.clear {
  clear: both;
}
.menu-opened {
  overflow: hidden;
}
.menu-opened:after {
  content: "";
  display: block;
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
}
.header-nav .container {
  height: 100%;
}
.header-nav__logo {
  float: left;
}
.header-nav__logo img {
  max-width: 100%;
  height: 100%;
}
.header-nav__hamburgger {
  display: none;
}
.header-nav__hamburgger {
  position: absolute;
  z-index: 30;
  top: 5px;
  right: 0px;
  display: block;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 2em;
  padding: 0 0.5em;
}
.header-nav__hamburgger:focus,
.header-nav__hamburgger:hover {
  outline: none;
  background: transparent;
}
@media screen and (min-width: 992px) {
  .header-nav__hamburgger {
      display: none;
  }
}
.header-nav__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.header-nav__menu .item-menu {
  background: #D70000;
  color: #fff;
  display: block;
  width: 50px;
  text-align: center;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.header-nav__menu a:hover {
  background: rgb(215,0,0);
}
.header-nav__menu a img {
  height: 18px;
}
.header-nav__menu a span {
  display: none;
}
.box-user {
  background: #D70000;
  color: #fff;
  display: block;
  padding: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 11px;
  height: 42px;
  overflow: hidden;
}
.box-user div {
  display: block;
  float: left;
}
.box-user img {
  height: 25px;
}
@media screen and (max-width: 991px) {
  .show-desk:not(.show) {
    display: none;
  }
  .d-mobile {
    display: block;
  }
  .d-inline-mobile {
    display: inline-block;
  }
  .d-desk {
    display: none;
  }
  .box-user {
    background: #FFF;
    color: #779198;
  }
  .item-reload {
    display: block;
    position: absolute;
    top: 18px;
    right: 65px;
    z-index: 99999;
    background: linear-gradient(190deg, rgba(119,145,152,1) 0%, rgba(65,79,82,1) 100%);
    border-radius: 5px;
    padding: 2px 7px 5px 7px;
  }
  .item-reload img {
    height: 12px;
  }
  .header-nav__menu {
      background: #FFF;
      border-top: 1px solid #779198;
      border-left: 1px solid #779198;
      border-bottom: 1px solid #779198;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      position: fixed;
      z-index: 10;
      top: 5px;
      bottom: 5px;
      right: -100%;
      width: 90%;
      bottom: 0;
      transition: right 0.3s ease-out;
      padding: 10px;
  }
  .header-nav__menu .item-menu {
    width: auto;
    background: #D70000;
    color: #fff !important;
    display: block;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 7px 1px;
    overflow: hidden;
    text-align: left;
  }
  .header-nav__menu a div {
    display: block;
    float: left;
    width: 37px;
    text-align: center;
  }
  .header-nav__menu a div img {
    height: 20px;
  }
  .header-nav__menu a span {
    display: block;
    float: left;
  }
  .header-nav .dropdown-menu {
    position: relative !important;
    float: none;
    top: -37px !important;
  }
}
.menu-opened .header-nav__menu {
  display: block;
}
@media screen and (max-width: 991px) {
  .menu-opened .header-nav__menu {
      right: 0;
  }
}
@media screen and (min-width: 992px) {
  .show-desk:not(.show) {
    display: block;
  }
  .d-mobile {
    display: none;
  }
  .d-inline-mobile {
    display: none;
  }
  .d-desk {
    display: block;
  }
  .header-nav__menu {
      float: left;     
  }
  .header-nav__menu,
  .header-nav__menu li {
    display: block;
    float: left;
    margin: 5px 2px 0px 2px;
  }
  .header-nav__menu li .item-menu {
      height: 42px;
      padding-top: 10px;      
      border-top: none;
      transition: all 0.3s ease-in-out;
  }
  .header-nav__menu li a:hover {
      -webkit-transform: translateY(0.3em);
      transform: translateY(0.3em);
      box-shadow: 0 5px 15px -10px #000;
  }
  .mlr-5 {
    margin-left: 30px !important;
  }
}

/*Menu tree*/
.box-vehicle ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.box-vehicle ul li li {
  padding-left: 30px;
}
.box-vehicle ul img {
  height: 12px;
  margin-right: 5px;  
}
.icon-folder {
  display: inline-block;
  background-image: url(assets/img/svg/pasta_fechada.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  padding-left: 25px;
}
.icon-folder-closed {
  display: inline-block;
  background-image: url(assets/img/svg/pasta_aberta.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  padding-left: 25px;
}
.caret {
  cursor: pointer;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}

.caret-down::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg);  
}

.nested {
  display: none;
}

.active {
  display: block;
}

@media screen and (max-width: 991px) {
  
  .table-resp {
    border: 0px;
    border-radius:0px;
  }
  .table-resp tr {
    border: 1px solid #779198;
    border-radius: 5px;
    display: block;
    padding: 5px;
    margin-bottom: 10px;
  }
  .table-resp td {
    display: inline-block;
    background: #F7F7F7;
    border-radius: 5px;
    border: 0px;
    margin: 3px;
    min-height: 28px;
    height: auto;
    padding: 5px;
  }
  .table-resp .td-vehicle {
    position: relative;
    background: transparent;
    width: 97%;
  }
  .btn-arrow {
    width: 30px; 
    height: 27px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .btn-arrow-down {
    width: 30px; 
    height: 27px;
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .table-resp .td-100 {
    width: 97%;
  }
  .table-resp span {
    margin-right: 5px;
    font-family: "Segoe UI Bold";
    font-weight: normal;
  }  
  .table-resp thead {
    display: none;
  }
  .table-resp thead {
    display: none;
  }
  .table-resp tbody tr:nth-of-type(odd) {
    background: transparent;
  }
}


@media screen and (max-width: 991px) {
  .tooltip {
    display: none;
  }
}
.dropdown-item {
  color: #779198;
  font-size: 13px;
}
.dropdown-item:hover {
  color:#FFF !important;
}
.dropdown-toggle::after {
  display: none;
}
.nav-link {
  padding: 0px;
}

a{
  cursor: pointer;
}
