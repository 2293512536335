
/*Menu Alert*/
.menu-alert {
    display: block;
    margin: 0px auto;
    padding: 0px;
    text-align: center;
    overflow: hidden;
  }
  
  .menu-alert li {
    width: 30%;
    margin: 0px auto;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 6px !important;
    padding: 3px 2px px 2px;
    background: #779198;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
  }

  .menu-alert hr {
    border-color: #FFFFFF;
    margin: 3px;
  }
  

  /* Modal */
.modal-alert {
  background-color: #779198 !important;
  color: #fff;
  font-weight: bold;
}

.modal-alert-green {
  background-color: #00A65A !important;
  color: #fff;
  font-weight: bold;
  border: 2px solid #fff;
}

.modal-alert-yellow {
  background-color: #FFD700 !important;
  color: #fff;
  font-weight: bold;
  border: 2px solid #fff;
}

.modal-alert-red {
  background-color: #B22222 !important;
  color: #fff;
  font-weight: bold;
  border: 2px solid #fff;
}

.content-alerts{
  width: 88%;
  float: left;
}

.content-alerts-sound{
  width: 10%;
  float: left;
}

.icon-sound{
  margin-top: 14px !important;
  margin-left: 0px !important;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 2px;
}

/** Menu Info **/
.menu-info {
  display: block;
  margin: 0px auto;
  padding: 0px;
  text-align: center;
  overflow: hidden;
}

.menu-info li {
  width: 15%;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 6px !important;
  padding: 3px 2px px 2px;
  background: #779198;
  border-radius: 5px;
  color: #FFF;
  text-align: center;
}