.grid-rastr td{
    vertical-align: middle;
}

.twenty_four{
    color: #FFFFFF;
    background-color: #E9967A;
}

.twelve{
    color: #000000;
    background-color: #F4FA58;
}

.six{
    color: #000000;
    background-color: #EAEAAE;
}

.three{
    color: #000000;
    background-color: #ADD8E6;
}

.one{
    color: #000000;
    background-color: #00FF7F;
}