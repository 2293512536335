/************************* Tab Ativação */
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 15px;
    background-color: #E9ECEF;
    color: #779198 !important;
    font-weight: bold;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff !important;
    font-weight: bold;
    background-color: #D70000;
    border-color: #dee2e6 #dee2e6 #fff;
}

.button_remove{
    color:  #D70000 !important;
}

.line_es .row{
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
}

.line_es .port{
    border-radius: 20px;
    background-color: #D70000;
    text-align: center;
    color: #fff;
}

.line_es input[type="radio"]{
    margin-left: 20px !important;
    vertical-align: middle;
}