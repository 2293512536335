.link_grid {
    margin-right: 5px;
    cursor: pointer;
}

.container_pagination {
    color: #779198;
    font-weight: bold;
    background-color: #E9ECEF;
    height: 100px;
}

.container_pagination ul.pagination li {
    background-color: #D70000 !important;
}

.container_pagination ul.pagination li a {
    text-decoration: none;
    color: #fff !important;
    font-size: 16px;
    background-color: #D70000 !important;
}

.container_pagination ul.pagination li.active a {
    color: #FA8072 !important;
    background-color: #fff !important;
    border: 0px !important;
    color: #D70000 !important;
}

.container_pagination ul.pagination li.active {
    background-color: #fff !important;
    border: 0px !important;
    color: #D70000 !important;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: #fff;
    background-color: #FF6347 !important;
}
